import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import './Blocoinfodois.css';

import monitor from '../../assets/img/blocoinfo/monitor.svg';
import circles from '../../assets/img/blocoinfo/circles.svg';
import qrcode from '../../assets/img/footer/qr-code-footer.png';
import doar from '../../assets/img/blocoinfo/doar.svg';

import translations from '../../_i18n/translations.json'
import { LANGUAGES } from '../../_i18n/languages.js';

export default class BlocoInfoDois extends Component {

  state = {
      loading: true,
      locale: ''
  }

  //Se não for passado idioma nenhum, carrega o idioma padrão.
  componentDidMount() {
      const currentUrlLang = window.location.pathname.split('/')[1]
      const currentLanguage = LANGUAGES[currentUrlLang]
      if(!currentLanguage) return window.location.href = `/${LANGUAGES.default}`

      this.setState({ locale: currentLanguage.code, loading: false })
  }

  toggleText(target) {
    document.getElementById("text__content-"+target).classList.toggle('invisible')
    document.querySelector(".faqTitle-"+target).classList.toggle('closed')
  }

  render() {
    return (
      <>
        <div className="blocodois" style={{position: 'relative'}}>
          {/* Contem as imagens que ficam no fundo do componente, atras do conteudo principal  */}
          <div className="container-fundo" style={{position: 'absolute'}}>
            <div style={{position: 'relative'}}>

              <div className="fundo-sombra">
                <img src={monitor} alt="fundo" />
              </div>

              

              {/* <div className="quadrado"></div> */}

              <div className="circulo">
                <img style={{marginTop: "-150px"}}  src={circles} alt="fundo" />
              </div>
            </div>

          </div>

          {/* Contem o conteudo principal com os textos  */}
          <div className="container-texto" style={{position: 'relative'}}>
            { Array.from({length: 7}, (_, i) => i + 1).map( (f, ind) => {
              return <>
                <div key={"blocoinfotitle-"+ind} className="text__title">
                  <h1 onClick={() => {this.toggleText(f)}} style={{margin: f == 1 ? "120px auto 0px auto" : "0 auto"}}  className={'closed faqTitle-'+f}>
                    <FormattedMessage id={"title-text-"+f} />
                  </h1>
                </div>
                <div key={"blocoinfocontent-"+ind} className="text__content invisible" id={"text__content-"+f}>
                  <p  style={{margin: "0 auto"}} className={'faqResponse-'+f}>
                    <FormattedMessage id={"response-text-"+f} defaultMessage={translations[this.state.locale]} values={{
                      i: (...chunks) => <i>{chunks}</i>,
                      b: (...chunks) => <b>{chunks}</b>,
                      a: chunks => {
                        let link="#";

                        switch(chunks) {
                          case "HERE": 
                          case "AQUI":
                            link = 'https://docs.google.com/spreadsheets/d/'+(this.props.yearSheet ? this.props.yearSheet : '1DCsji4AVPTbM59P5YI1q8T3izcSF24Ipa9KwHBIPdPs')+'/edit?usp=sharing'
                            break; 
                          case "Clique aqui para conferir os dados.":
                          case "Click here to check the data":
                            link = 'https://pesquisadoacaobrasil.org.br/'
                            break;
                          case "Clique aqui para acessar":
                          case "Click here to access":
                            link = 'https://www.idis.org.br/publicacoesidis/brasil-giving-report-2020/'
                            break;
                          case "Clique aqui para acessar a pesquisa":
                          case "Click here to access the survey":
                            link = 'https://gife.org.br/censo-gife/'
                            break;
                          case "Clique aqui para acessar os dados":
                          case "Click here to access the data":
                            link = 'https://bisc.org.br/conteudo-dados/'
                            break;
                          case "neste link":
                          case "this link":
                            link = 'https://mapaosc.ipea.gov.br/'
                            break;
                          case "contato@diadedoar.org.br":
                            link = 'mailto:contato@diadedoar.org.br'
                            break;
                        }
                        
                        return <a
                          target="_blank"
                          href={link}
                        >
                          {chunks}
                        </a>
                      }
                    }}/>
                  </p>
                </div>
              </>
            })}
          </div>
        </div>
      </>
    );
  }
}
