import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import './Pesquisa.css'
import { FormattedMessage } from "react-intl";

function PesquisaLine({line, allHeaders}) {
    const [lastValue, setLastValue] = useState('')
    const [header, setHeader] = useState('')
    const [progress, setProgress] = useState(0)
    const [chart, setChart] = useState("")
    const [opened, setOpened] = useState(false)
    
    let url_atual = window.location.pathname;

    useEffect(() => {
        let firstYearIndex = 7;
        //Pega ultimo valor da pesquisa
        let lastYearIndex = firstYearIndex
        let initialDate = line.length - 1;
        for (let i = 0; initialDate-i >= firstYearIndex; i++) {
            if (line[initialDate-i]) {
                setLastValue(line[initialDate-i]);
                setHeader(allHeaders[initialDate-i]);
                lastYearIndex = initialDate-i;
                break;
            }
        } 

        //Pega diferença do ultimo valor da pesquisa e o penultimo
        if (lastYearIndex != firstYearIndex) {
            for (let j = 1; lastYearIndex-j >= firstYearIndex; j++) {
                if (line[lastYearIndex-j]) {
                    let perc = (100 * parseFloat(line[lastYearIndex])) /parseFloat(line[lastYearIndex-j]) 
                    perc -= 100;
                    setProgress(perc)
                    break;
                }
            } 
        }

        //Build chart
        let xAxisArray = [];
        let yAxisArray = [];
        for (let k = firstYearIndex; k <= lastYearIndex; k++) {
            if (line[k]) {
                xAxisArray.push(allHeaders[k]);
                yAxisArray.push(line[k]);
            }
        }

        console.log(xAxisArray,yAxisArray)

        setChart({
            title: {
                text: (url_atual == '/en') ? line[firstYearIndex] : line[2]
            },
                credits: false,
            legend: {
                enabled:false
            },
            xAxis: {
                categories: xAxisArray
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            series: [{
                name:'',
                data: yAxisArray
            }],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    }
                }]
            }
        })

    }, [])


    return <>
        <div className="pesq-header" onClick={() => { setOpened(!opened) }} style={{cursor:'pointer'}}>
            <span className="title">
                <strong >
                    {line[0]}
                </strong>
            </span>
            <span className="floating-value">
                <strong>
                   R$ {lastValue.toLocaleString((url_atual == '/en') ? 'en-US' : 'pt-BR')}
                </strong>
                <span>
                    {header}
                </span>
            </span>
            <span className={"progress " + (progress > 0 ? 'positive' : (progress < 0 ? 'negative' : ''))}>
                {(progress).toFixed(2)}% { progress > 0 ? '⬈' : (progress < 0 ? '⬊' : '')}
            </span>
            <span style={{color:'#778287'}}>
                { opened ? 'ᐱ' : 'ᐯ'}
            </span>
        </div>
        <div className={"pesq-content " + (opened ? 'open' : '')}>
            <div className="description">
                {(url_atual == '/en') ? line[4] : line[3]}
            </div>
            <div>
                <div className="pesq-chart-wrapper">
                    <HighchartsReact highcharts={Highcharts} options={chart} />
                </div>
            </div>
            {(url_atual == '/en') && 
                <em style={{fontSize: '10px',textAlign: 'left',marginBottom: '20px'}}>
                    <FormattedMessage id="warning-reais" />
                </em>
            }
            <div style={{display:'flex', justifyContent:'flex-start'}}>
                <a href={line[5]}>
                    Fonte: {line[1]}
                </a>
            </div>
        </div>
    </>
}

function Pesquisa({sheet}) {
    
    return <>
        <h2 className="chart-title">
            <FormattedMessage id="searches-on-brazil" />
        </h2>
        <div className="list pesq">
            {sheet && sheet.filter(line => line[0] && line[0] != 'Nome da Pesquisa').map((line, index) => {
                return <PesquisaLine key={"pesquisaLine-"+index} line={line} allHeaders={sheet[0]}/>
            })}
        </div>
        
    </>
}

export default Pesquisa;
