import React from 'react';
import { FormattedMessage } from "react-intl";
import "../imprensa/Imprensa.css";
import Loading from '../../assets/img/lista-doadores/loading.svg';
import imgcanto from '../../assets/img/blocoinfo/imgcanto.svg';

// import styled from 'styled-components';

// const Animacao = styled.div `
//   transition: opacity 10s;
//   opacity: ${({ isVisible }) => (isVisible) ? 1 : 0};
//   width: 80%;
//   display: flex;
//   justify-content: space-around;
//   flex-wrap: wrap;
// `;
// const Teste = styled.div `
//   transition: opacity 10s;
//   opacity: ${({ teste }) => (teste) ? 1 : 0};
// `;

const Imprensa = ({valor, capa = false}) => {

  const [materia, setMateria] = React.useState("");
  const [contador, setContador] = React.useState(8);
  // Alterna o loading entre visível ou não
  const [visible, setVisible] = React.useState({
    Loading: { display: "block" },
  });

  React.useEffect(() => {

    setMateria([
      dados(valor?.reverse())
    ])
    if(materia !== "") {
      setVisible({
        Loading: { display: "none" },
      });
    }
  }, [valor]);

  const dados = (valor) => {
    let saidaDados = [];
    console.log(valor);
    valor && valor.map((materia) => {
      if (capa && materia[1]) {
        saidaDados.push({
          titulo: (materia[0]),
          veiculo: (materia[2]),
          link: (materia[1]),
        });
      } else {
        if (materia["Título"] && materia["Título"] != 'Título') {
          saidaDados.push({
            titulo: (materia["Título"]),
            veiculo: (materia["Veículo"]),
            link: (materia["Link"]),
          });
        }
      }
    })
    return saidaDados.reverse();
  } 


  let listaObject = [];
  

  materia["0"] && materia["0"].map((valor) => {
    if (listaObject.length < contador) { 
      listaObject.push({
        titulo: valor.titulo,
        veiculo: valor.veiculo,
        link: valor.link
      })
    }
  })

  let lista = []

  
  for (var value of listaObject) {
    lista = 
    listaObject && listaObject.map ((valor, index) => (
      <div key={index}>
      
      <a href={valor.link} target="_blank">
      <div className="list__item" style={{position: 'relative', overflow:'hidden'}}>
      
        {capa ?
          <>
            {valor.veiculo && <img style={{position:'absolute', width:'100%', top:'0', left:'0'}} src={valor.veiculo} />}
            <p style={{background:'#fff', marginTop:'10px', zIndex:'10', padding:'10px'}}>{valor.titulo}</p>
          </>
        :
          <>
            <p>{valor.veiculo}</p>
            <p>"{valor.titulo}"</p>
          </>
        }
      
      </div>
      </a>
      </div>
    )) 
  };

    React.useEffect(() => {
      setContador(contador
        );
    }, [contador])


  return (
    <>
    
    <div className="press" style={{position: 'relative'}}>
      {!capa && <div className="imgdecorativo">
        <img src={imgcanto} alt="fundo" style={{top: '-240px'}}/>
      </div>}
    <h2>
    <FormattedMessage id={capa ? "reports" : "press-title"} />
    </h2>
    

    <div className="list">
    {lista}
    </div>
    <div style={visible.Loading}>
    <img src={Loading} alt="imagem de loading" />
    </div>
    
    {(valor && valor.length > contador) && 
    <div className="button" onClick={() => setContador(contador + 4)}>
        + <FormattedMessage id="load-more" />
    </div>
    }
    </div>
    <div style={{height:'50px',width:'100%'}}>
    
    </div>
    </>
  )
}

export default Imprensa
