import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from 'moment';

import "./EvolucaoSemanal.css";
import { FormattedMessage } from "react-intl";
import { headerLabels } from "../../App";

const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junnho",
  "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
];

const EvolucaoSemanal = (props) => {

  // A const possui os valores iniciais que servirão de referencia para mudar os valores para dolar
  const [idioma, setIdioma] = useState({
    legendaDoacoes: 'Doações (em milhões)',
    legendaDoadores: 'Doadores',
    indiceDoacoes: 2,
    indiceDoadores: 3,
    localeString: "pt-BR",
  });

  const [series, setSeries] = useState ({
    seriesDoacoes : [],
    seriesDoadores: [],
    categories: []
  }
  )



  useEffect(() => {
    if (props.valor["Doações"]) {

      let seriesDoacoes = []
      let seriesDoadores = []
      let categories = []

      let lastSunday = new Date()
      lastSunday.setDate(lastSunday.getDate() - lastSunday.getDay());
      
      let totalByDate = {}
      let allLines = props.valor["Doações"].filter((linha) => ['', ...headerLabels].indexOf(linha[4]) == -1)
      for(let line of allLines) {
        let lineDate = new Date(line[4])
        // lineDate.setDate(lineDate.getDate() - lineDate.getDay());
        // let lineDateKey = lineDate.toLocaleString([], {month: '2-digit', day: '2-digit' })

        if (!monthNames[lineDate.getMonth()]) {
          break;
        }
        let lineDateKey = monthNames[lineDate.getMonth()].substring(0,3)+'/'+lineDate.getFullYear()

        if (!totalByDate[lineDateKey]) {
          totalByDate[lineDateKey] = {'donors': 0, 'donations':0}
        }

        totalByDate[lineDateKey]['donors']++;
        totalByDate[lineDateKey]['donations'] += ((line[idioma.indiceDoacoes]/1000000));


      }

      // for (let dateC = 0; dateC <= 30*7; dateC += 7) {
      for (let dateC of monthNames) {
        // lastSunday.setDate(lastSunday.getDate() - dateC)
        // let sundayKey = lastSunday.toLocaleString([], {month: '2-digit', day: '2-digit' })
        // categories.push(sundayKey)

        categories.push(dateC.substring(0,3) + '/' + (props.year ? props.year : '2024'))
        
        // if(totalByDate[sundayKey]) {
        //   seriesDoacoes.push(totalByDate[sundayKey].donations)
        //   seriesDoadores.push(totalByDate[sundayKey].donors)
        // } else {
        //   seriesDoacoes.push(0)
        //   seriesDoadores.push(0)
        // }
        if(totalByDate[dateC.substring(0,3) + '/' + (props.year ? props.year : '2024')]) {
          seriesDoacoes.push(totalByDate[dateC.substring(0,3) + '/' + (props.year ? props.year : '2024')].donations)
          seriesDoadores.push(totalByDate[dateC.substring(0,3) + '/' + (props.year ? props.year : '2024')].donors)
        } else {
          seriesDoacoes.push(0)
          seriesDoadores.push(0)
        }
      }


      console.log(totalByDate)

      setSeries ({
        ...series,
        seriesDoacoes : seriesDoacoes,
        seriesDoadores: seriesDoadores,
        categories: categories
      })
      formatValue()
    }
  }, [props]);

  useEffect(() => {
    updateSeries()
  }, [series.seriesDoacoes])

  const chartSeries = (values, index) => {
    let final = []
    values.forEach((item) => {
      if (item[0] !== "" && !isNaN(item[index])) {
        final.push(Math.round(item[index]))
      }
    })
    return final
  }

  const chartCategories = (array) => {
    const categorias = []
    array.forEach(item => {
      const date = moment(item[1]).format('DD[/]MM')
      if (date !== "Invalid date") {
        categorias.push(date)
      }
    })
    return categorias
  }

  const [chartOptions, setChartOptions] = useState({
    title: {
      text: ''
    },
    credits: false,
    xAxis: {
      categories: [""]
    },
    yAxis: {
      labels: {
        formatter: function () {
          return this.value.toLocaleString(idioma.localeString);
        },
      },
      title: {
        text: false
      }
    },
    plotOptions: {
      line: {
        dataLabels: {

          enabled: true
        },
        enableMouseTracking: false
      }
    },
    series: [{
      color: "#AE1920",
      name: idioma.legendaDoacoes,
      data: [''],
      dataLabels: {
        formatter: function () {
          if (!this.y) {
            return '0'
          }
          return (parseFloat(this.y.toFixed(2))).toLocaleString(idioma.localeString);
        }
      }
    }, {
      color: "#4C87B1",
      name: idioma.legendaDoadores,
      data: ['']
    }]

  });

  const updateSeries = () => {
    setChartOptions({
      xAxis: {
        categories: series.categories
      },
      series: [{
        name: idioma.legendaDoacoes,
        data: series.seriesDoacoes
      }, {
        name: idioma.legendaDoadores,
        data: series.seriesDoadores
      }]

    })

  }

  //   Verifica o idima do site de acordo com a URL, depois altera as informações do gráfico de acordo com o idioma
  // Altera os parametros quando o site estiver em ingles, os dados são buscados na URL
  const formatValue = () => {
    const url_atual = window.location.pathname;
    if (url_atual !== "/pt") {
      setIdioma({
        legendaDoacoes: 'Donations (in millions)',
        legendaDoadores: 'Donors (in thousands)',
        indiceDoacoes: 3,
        localeString: "en-US"
      })
    }
  };

  return (

    <div className="container-setores">

      <h2 className="chart-title-setores">
        <FormattedMessage id="chart-weekly-evolution" />
      </h2>



      <div className="grafico">
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>

      
    </div>
  );
};

export default EvolucaoSemanal;
