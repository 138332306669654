import React, { Component, useEffect, useState } from 'react';
import './Lista-Doadores.css';
import Loading from '../../assets/img/lista-doadores/loading.svg';
import shape from '../../assets/img/lista-doadores/square-shapes.svg';
import { FormattedMessage } from 'react-intl';
import { headerLabels } from '../../App';

let ListaDoadores = (props) => {
  const [doacoes, setDoacoes] = useState({})
  const [doacoesExibidas, setDoacoesExibidas] = useState([])
  const [num, setNum] = useState(1500)

  let url_atual = window.location.pathname;
  let indexMoeda = 2;
  let donationsByDonor = {}

  useEffect(() => {
    if (props.valor != undefined) {      
      if (url_atual == '/en') {
        indexMoeda = 3
      }

      for (let donation of props.valor.filter((linha) => ['', ...headerLabels ].indexOf(linha[1]) == -1)) {
        if (donationsByDonor[donation[1]] == undefined) {
          donationsByDonor[donation[1]] = donation
          if (!Array.isArray(donationsByDonor[donation[1]])) {
            break;
          }
          donationsByDonor[donation[1]].push(donation[indexMoeda])
        } else {
          donationsByDonor[donation[1]][donationsByDonor[donation[1]].length - 1] += donation[indexMoeda]
        }
      }
    } else {
      donationsByDonor=[];
    }
    
    console.log(donationsByDonor)

    setDoacoes(donationsByDonor)
    setDoacoesExibidas(Object.keys(donationsByDonor).map((i) => donationsByDonor[i]).sort((a,b) => {
      if ( a[a.length - 1] > b[b.length - 1] ){
        return -1;
      }
      if ( a[a.length - 1] < b[b.length - 1] ){
        return 1;
      }
      return 0;
    }).slice(0, num))
  }, [props.valor])

  let moreResults = () => {
   setNum(num + 50)
  };

  let handleSubmit = (donation) => {
    window.open(donation[12], '_blank')
  };
    
  return (
    <>
    <div className="container-donations-list">
      <div className="donations">
        <div className="donations-top">
          <div className="donations-top-text">
            <div className="donations-top-title">
              <h2 style={{whiteSpace: 'nowrap'}}>
                <FormattedMessage id="donations-top-title" />{' '+ (props.year ? props.year : '2024') + ':'}
              </h2>
            </div>
            {/* <div className="donations-top-description">
              <p>
                <FormattedMessage id="donations-top-description" />
                {Object.keys(doacoes).length || '0'}
                <FormattedMessage id="donations-top-description-two" />
              </p>
            </div> */}
          </div>
          {/* <div className="donations-top-img">
            <img src={shape} alt="" />
          </div> */}
        </div>
        <div className="donations-list">
          <div className="lista">
            <div className='list-item'>
                <div><strong><FormattedMessage id="doador" /></strong></div>
                <div style={{ overflowWrap: "break-word", marginLeft: '10px'}}><strong><FormattedMessage id="total_doado" /></strong></div>
                <div style={{ overflowWrap: "break-word", marginLeft: '10px'}}><strong><FormattedMessage id="ultima_doacao" /></strong></div>
            </div>
            {doacoesExibidas.map((donation, index) => {
              return <div key={'listdonations-'+index} className='list-item' style={{cursor:"pointer"}} onClick={() => {handleSubmit(donation)}}>
                <div>{donation[1]}</div>
                <div style={{ overflowWrap: "break-word", marginLeft: '10px'}}>{(url_atual == '/en') ? '$' : 'R$'} {(donation[donation.length - 1])?.toLocaleString(((url_atual == '/en') ? 'en-US' : 'pt-BR'), {minimumFractionDigits: 2})}</div>
                <div style={{ overflowWrap: "break-word", marginLeft: '10px'}}>{(url_atual == '/en') ? '$' : 'R$'} {(donation[indexMoeda])?.toLocaleString(((url_atual == '/en') ? 'en-US' : 'pt-BR'), {minimumFractionDigits: 2})}</div>
              </div>
            }) || (
              <div className="loading">
                <img src={Loading} alt="Carregando..." />
              </div>
            )}
          </div>
          {/* <div className="load-more">
            <div className="button" onClick={moreResults}>
              + <FormattedMessage id="load-more" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
    <a className="estiloBtn"
        href={"https://docs.google.com/spreadsheets/d/"+(props.yearSheet ? props.yearSheet : '1DCsji4AVPTbM59P5YI1q8T3izcSF24Ipa9KwHBIPdPs')+"/edit#gid=0"}
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <button > */}
          <FormattedMessage id="chart-indicators-button" />
        {/* </button> */}
      </a>
    </>
  );
}

export default ListaDoadores;
